import { Injectable } from '@angular/core';
import { Customer } from '../dtos/Customer';


/**
 * Model containing all the logged user useful data
 */
@Injectable({
  providedIn: 'root',
})
export class UserModel {
    
    
    // Operations related to users permissions
    readonly APP_CENTROALUM_VIEW_SECTION_ORDERS = 'APP_CENTROALUM_VIEW_SECTION_ORDERS';
    
    
    readonly APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR = 'APP_CENTROALUM_VIEW_SECTION_CONFIGURATOR';
    
    
    readonly APP_CENTROALUM_VIEW_SECTION_EXPANSION_ROUTES = 'APP_CENTROALUM_VIEW_SECTION_EXPANSION_ROUTES';
    
    
    readonly APP_CENTROALUM_VIEW_SECTION_INCIDENCES = 'APP_CENTROALUM_VIEW_SECTION_INCIDENCES';
              

    isCustomer = false;
    

    customer: Customer;
    
    
    relatedComercial = '';
}

import { Component, Output, EventEmitter, HostListener } from '@angular/core';
import { LocalesService, ButtonContainerComponent } from 'turbogui-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserModel } from 'src/main/model/models/user.model';
import { CustomerService } from 'src/main/controller/customer.service';


/**
 * A button that is used to select a customer from the list of customers.
 * It will be enabled only if the current user is allowed for this kind of operation
 */
@Component({
    selector: 'app-select-customer-button',
    standalone: true,
    imports: [CommonModule, FormsModule, ButtonContainerComponent],
    providers: [],
    templateUrl: './select-customer-button.component.html',
    styleUrls: ['./select-customer-button.component.scss']
})


export class SelectCustomerButtonComponent extends ButtonContainerComponent{
    
    
    selectedCustomerCodigo = '';
    
        
    selectedCustomerNombre = '';    
    

    @Output() customerCleared = new EventEmitter();
    
    
    @Output() customerConfirmed = new EventEmitter<{ codigo: string; nombre: string }>();
    
    
    releaseOnMouseUp = false;
    
    
    defaultOpacity = .7;
    
    
    hoverOpacity = .8;
    
    
    clickOpacity = 1;
    
    
    clickScale = 1.07;
    
    
    disabledOpacity = .7;
    
    
    constructor(public ls: LocalesService,
                public userModel:UserModel,
                public customerService: CustomerService) {
    
        super();
    }
    
    
    /**
     * Search for a customer to the new order that will be created
     */
    @HostListener('click', ['$event'])
    onClick(): void {
        
        if(this.selectedCustomerCodigo === ''){

            this.customerService.selectCustomer((codigo, nombre) => {
                            
                if(codigo != ''){
                    
                    this.selectedCustomerCodigo = codigo;
                    this.selectedCustomerNombre = nombre;
             
                    this.showCustomerDetails();
                }
            });

        }else{
            
            this.showCustomerDetails();
        }
    }
    
    
    /**
     * Show the information for the currently selected customer
     */
    showCustomerDetails(){
        
        this.customerService.showCustomerDetails(this.selectedCustomerCodigo,
            (selection) =>{
            
            if(selection === 0){

                this.selectedCustomerCodigo = '';
                this.selectedCustomerNombre = '';
                this.customerCleared.emit();
                
                this.onClick();
                
            }else{
            
                this.customerConfirmed.emit({ codigo: this.selectedCustomerCodigo, nombre: this.selectedCustomerNombre });
            }
        })
    }
    
    
    setCustomer(codigo:string, nombre:string){
        
        this.selectedCustomerCodigo = codigo;
        this.selectedCustomerNombre = nombre;
    }
}

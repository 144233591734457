import { Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewsService, FadeAnimationClass, View, LocalesService, BrowserService, ButtonImageComponent, ButtonContainerComponent } from 'turbogui-angular';
import { ContentFadeAnimationClass } from '../../../view/animations/content-fade.animation';
import { IncidencesViewService } from 'src/main/view/views/incidences-view/incidences-view.service';
import { SelectCustomerButtonComponent } from '../../components/select-customer-button/select-customer-button.component';


/**
 * incidences view
 */
@Component({
    selector: 'app-incidences-view',
    standalone: true,
    imports: [CommonModule, ButtonImageComponent, ButtonContainerComponent, SelectCustomerButtonComponent],
    providers: [IncidencesViewService],
    templateUrl: './incidences-view.component.html',
    animations: [FadeAnimationClass.getTrigger('fadeIn'), ContentFadeAnimationClass.getTrigger()],
    styleUrls: ['./incidences-view.component.scss']
})


export class IncidencesViewComponent extends View implements OnDestroy {


    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public incidencesViewService: IncidencesViewService,
                public browserService: BrowserService,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, incidencesViewService);

        this.browserService.setCurrentUrlURI('');
    }
    
    
    onCustomerCleared(){
        
    }
    
    
    onCustomerSelected(event:any){
        
        alert(event.codigo + '-----' + event.nombre);
    }


    /**
     * Remove subscriptions on component removal
     */
    ngOnDestroy() {

        this.browserService.setCurrentUrlURI('');
    }
}

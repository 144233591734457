import { Injectable } from '@angular/core';
import { ViewsService, TurboApiCallerService, DialogService, ViewService, LocalesService } from 'turbogui-angular';
import { CustomerService } from '../../../controller/customer.service';
import { OrdersMenuViewComponent } from '../orders-menu-view/orders-menu-view.component';
import { ProductLineEditor } from '../../components/product-line-editor/product-line-editor.component';


/**
 * The service for the incidences view
 */
@Injectable()
export class IncidencesViewService extends ViewService {

    
    /**
     * The customer code that is selected for incidence creation
     */
    currentCustomer = '';
   
   
    /**
     * The customer name that is selected for incidence creation
     */
    currentCustomerNombre = '';
    
    
    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public customerService: CustomerService,
                public apiService: TurboApiCallerService) {
    
        super();
        
//        this.selectCustomer();
    }
    
    
    selectCustomer(){
        
        this.customerService.selectCustomer((codigo, nombre) => {
                                        
            if(codigo != ''){
                
                this.customerService.showCustomerDetails(codigo, (selection) =>{
                
                    if(selection === 0){
                                
                        this.selectCustomer();
            
                    }else{
            
                        this.currentCustomer = codigo;
                        this.currentCustomerNombre = nombre;
                        this.createNewOrderLine();
                    }
                })
                
            }else{
 
                this.viewsService.pushView(OrdersMenuViewComponent);
            }
        });
    }
    
    
    createNewOrderLine(){

        this.dialogService.addDialog(ProductLineEditor, {
            width: '900px',
            texts: [this.ls.t('INCIDENCE_LINE', 'centroalum/app')],
            data: { isLoadLocalFileAvailable : false }
        }, () =>{
            
        });
    }
    
}
import { Component, ElementRef, Inject } from '@angular/core';
import { LocalesService, DialogBaseComponent, TurboApiCallerService, DelayedMethodCallManager, ViewsService, DialogService,
        DialogMultipleOptionComponent, DialogSingleSelectionListComponent, ButtonContainerComponent, 
        TurboGuiAngularModule} from 'turbogui-angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NumericUtils, ObjectUtils, StringUtils } from 'turbocommons-ts';
import { OrderLine } from 'src/main/model/dtos/OrderLine';
import { AccesoriesEditComponent } from './accesories-edit.component';
import { WinkhausComponent } from './winkhaus.component';
import { LoadOrderFileComponent } from './load-order-file.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
 

/**
 * Component that lets us find a product and edit the information to add a new line to an order, incidence or others.
 */
@Component({
    selector: 'app-product-line-editor',
    standalone: true,
    imports: [CommonModule, FormsModule, MatFormFieldModule, MatAutocompleteModule, MatButtonModule, MatInputModule,
              ButtonContainerComponent, TurboGuiAngularModule],
    providers: [],
    templateUrl: './product-line-editor.component.html',
    styleUrls: ['./product-line-editor.component.scss']
})


export class ProductLineEditor extends DialogBaseComponent{


    static readonly DIALOG_CLASS_NAME = "OrderNewLineComponent";
    

    stringUtils = StringUtils;


    isLoadingAutocomplete = false;
    
    
    /**
     * Contains the main title to show on this dialog. It will be loaded from the texts array that is passed to this dialog
     */
    dialogTitle = '';
    
    
    /**
     * This flag will be enabled or disabled when creating the ProductLineEditor dialog, by passing isLoadLocalFileAvailable value to the data object
     */
    isLoadLocalFileAvailable = true;
    
    
    filteredProducts:any = [];
    
    
    lineData:OrderLine = new OrderLine();


    delayedMethodCallManager: DelayedMethodCallManager = new DelayedMethodCallManager();
    

    constructor(public elementRef: ElementRef, 
                public dialogRef: MatDialogRef<DialogBaseComponent>,
                public ls: LocalesService,
                public apiService: TurboApiCallerService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
       
       super(elementRef, dialogRef);
       
       this.dialogTitle = this.data.texts[0];
       this.isLoadLocalFileAvailable = this.data.data.isLoadLocalFileAvailable;
    }
    
    
    onProductCodeSearchChange(inputValue:string){
        
        this.filteredProducts = [];
        this.lineData = new OrderLine();
        this.lineData.product.codigo = inputValue;
        
        if(StringUtils.isEmpty(inputValue) || inputValue.length < 2){
            
            this.delayedMethodCallManager.cancel();
            this.isLoadingAutocomplete = false;
            return;
        }
        
        this.isLoadingAutocomplete = true;
        
        this.delayedMethodCallManager.queue(() =>  {
            
            this.apiService.call('products/products-list', {codeFilter: inputValue}, {busyState: false})
                .then(response => {
                
                if(response.length === 1){
                                                    
//                    this.ordersNewOrderViewService.setProductToCurrentLine(response[0].codigo);
                    
                }else{
                    
                    this.filteredProducts = response;
                }                
                
                this.isLoadingAutocomplete = false;
                
            });
        });
    }
    
    
    loadLocalFile(){

        this.dialogService.addDialog(LoadOrderFileComponent, {
                modal: false,
                width: '900px'
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                   this.onCancelLineButtonClick();
                }
            });
    }
    
    
    onWinkHausClick(){
        
        // TODO - we send 'winkhaus' as a text to prevent a bug with the dialogs hash on the dialogservice class.
        // When release build is generated, the hash is not unique cause the WinkhausComponent class is minified to 't'
        // and the hash is the same as another open dialog, which is not really the same.
        this.dialogService.addDialog(WinkhausComponent,
            {
                width: '800px',
                modal: false,
                texts: ['winkhaus']
            },
            (selection) =>{
            
                if(selection.index === 0){
                    
                    // TODO
                }
            });
    }
    
    
    setProductToCurrentLine(codigo:string, done: null|(() => void) = null){
            
        let parameters:any = {codigos: [codigo]};
        
//        if(!StringUtils.isEmpty(this.order.customer.codigo)){
//            
            parameters.cliente = 1;//this.order.customer.codigo;
//        }
        
        this.apiService.call('products/product-data', parameters).then(response => {
            
            this._addProductDataToLineBeingEdited(response[0]);
            
            if(done !== null){
            
                done();
            }  
        });
    }
    
    
    private _addProductDataToLineBeingEdited(productData:any){

        this.lineData.product = productData.product;
        this.lineData.finish = productData.finish;
        this.lineData.unitPrice = Number(productData.unitPrice);
        this.lineData.unidadesMedida = productData.measureUnits;
        this.lineData.family = productData.family;
        this.lineData.subFamily = productData.subFamily;

        for(const accesory of productData.accesories){

            if(accesory.accesorio.indexOf('PO') !== 0 && !NumericUtils.isInteger(accesory.accesorio.substr(0, 1))){

                this.lineData.accesories.push(ObjectUtils.clone(accesory));
            }
        }

        if(productData.discount !== null){

            this.lineData.discount = productData.discount;
            this.lineData.discount.dto = String(this.lineData.discount.dto).replace(',', '.');
        }

        // If the previous order line has a selected variant and this product contains it on the list of selectable ones, we will automatically select it
//        if(this.order.orderLines.length > 0){
//
//            const previousLine = this.order.orderLines[this.order.orderLines.length - 1];
//
//            if (previousLine.variant.variante !== ''){
//
//                for(const variant of productData.variants){
//
//                    if(variant.variante === previousLine.variant.variante){
//
//                          this.lineData.variant.descripcion = variant.descripcion;
//                          this.lineData.variant.grupo = variant.grupo;
//                          this.lineData.variant.variante = variant.variante;
//                          
//                          break;
//                    }
//                }
//            }
//       }
    }


    selectColorOrMeasureUnit(){

        const options = [];

        if(this.lineData.finish !== null &&
           !StringUtils.isEmpty(this.lineData.finish.grupoVariante)){

            options.push(this.ls.t('SELECT_COLOR', 'centroalum/app'));
        }

        if(!StringUtils.isEmpty(this.lineData.product.codigo)){

            options.push(this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app'));
        }

        this.dialogService.addDialog(
            DialogMultipleOptionComponent,
            {
                width:'800px',
                modal: false,
                texts: [this.ls.t('ADD_COLOR_OR_MEASURE_UNIT', 'centroalum/app')],
                options: options
            },
            selection =>{

                if(selection.value === this.ls.t('SELECT_COLOR', 'centroalum/app')){

                    this.showVariantsList();
                }

                if(selection.value === this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app')){
                    
                     this.showMeasureUnitsList();
                }
            });
    }


    showVariantsList(){

        this.apiService.call('products/variants-list',
            {grupo: this.lineData.finish.grupoVariante})
            .then(response => {
                    
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_A_VARIANT', 'centroalum/app'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.variante + ' - ' + a.descripcion)
                },
                (selection) =>{
    
                    if(selection.index >= 0){
    
                        this.lineData.variant = response[selection.index];
                    }
                });
        });
    }


    showMeasureUnitsList(){

        this.apiService.call('products/measure-units-list',
            {producto: this.lineData.product.codigo})
            .then(response => {
            
            this.dialogService.addDialog(
                DialogSingleSelectionListComponent,
                {
                    width:'800px',
                    modal: false,
                    texts: [this.ls.t('SELECT_MEASURE_UNIT', 'centroalum/app'), '', this.ls.t('SEARCH', 'turbodepot/user-interface')],
                    options: response.map((a:any) => a.code)
                },
                (selection) =>{
    
                    if(selection.index >= 0){
    
                        this.lineData.product.unidadDeVenta = response[selection.index].code;
                        this.lineData.unidadesMedida = response[selection.index];
                    }
                });
            
        });
    }

    
    getUnitPriceValue(){
        
        // TODO - aixo estava abans aqui
//        ordersNewOrderViewService.calculateLineUnitPrice(ordersNewOrderViewService.orderLineBeingEdited);
    }
    
    
    showAccesoriesEditor(){

        this.dialogService.addDialog(
                AccesoriesEditComponent,
                {
                    width: '800px',
                    texts: [this.ls.t('EDIT_ACCESORIES', 'centroalum/app')]
                });
    }
    
    
    formatUnits(){
        
        this.lineData.units = this.lineData.units ?? 0;
    }
    
    
    isUserACustomer(){
    
        return true;    
        // TODO - aixo estava abans
//        return userModel.isCustomer
    }
    
    
    onDiscountChange(event:any){
        
        // Format the received discount
        let formattedDiscount = StringUtils.isEmpty(event.target.value) ? '0' : StringUtils.replace(event.target.value, ',', '.');
        
        // Obtain the original discount value to compare with the modified one
        let originalDiscount = this.lineData.discount.dto;
        
        if(!StringUtils.isEmpty(this.lineData.isDiscountManuallyModified)){
            
            originalDiscount = this.lineData.isDiscountManuallyModified.split(';')[0];
        }
        
        originalDiscount = StringUtils.isEmpty(originalDiscount) ? '0' : StringUtils.replace(originalDiscount, ',', '.');
        
        // Check if the discount is manually modified
        if(originalDiscount !== formattedDiscount){
            
            this.lineData.isDiscountManuallyModified = originalDiscount + ';' + formattedDiscount;
        }
        
        this.lineData.discount.dto = formattedDiscount;   
    }

    
    isAddLineButtonEnabled(){

        return true;
        // TODO - aixo era abans
//        ordersNewOrderViewService.isSaveLineEnabled();
    }
    
    
    onAddLineButtonClick(){
        
        
        
        // TODO - aixo era abans
//        ordersNewOrderViewService.addLineBeingEditedToOrder();
//        cancelLineEdit();
    }
    
    
    onCancelLineButtonClick(){
        
        this.lineData = new OrderLine();
       
//        this.ordersNewOrderViewService.order.total = this.ordersNewOrderViewService.calculateOrderTotal(this.ordersNewOrderViewService.order.orderLines);
        
        this.closeDialog(0);
    }
}

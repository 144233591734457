<div class="mainElementsContainer">
    
    <app-select-customer-button id="selectCustomerButton"
            (customerCleared)="onCustomerCleared()"
            (customerConfirmed)="onCustomerSelected($event)" />  
    
    <h2>{{this.ls.t('NEW_INCIDENCE', 'centroalum/app') + ': ' + this.incidencesViewService.currentCustomerNombre}}</h2>
    
    
    <div class="linesContainer" [@fadeIn]
        *ngIf="true">
        
        <h3 class="linesTitle">{{ls.t('INCIDENCE_LINES', 'centroalum/app')}}</h3>
        
    </div>
    
</div>
<h3>{{this.ls.t('CUSTOMER_DATA', 'turbodepot/shopping')}}</h3>

<h5>{{this.ls.t('CODE', 'turbodepot/personal-data')}}:</h5>
<p>{{this.data.data.customerCodigo === '' ? '-' : this.data.data.customerCodigo}}</p>

<h5>{{this.ls.t('NAME', 'turbodepot/personal-data')}}:</h5>
<p>{{this.customerData.nombre === '' ? '-' : this.customerData.nombre}}</p>

<h5>{{this.ls.t('PHONE', 'turbodepot/personal-data')}}:</h5>
<p>{{this.customerData.telefono === '' ? '-' : this.customerData.telefono}}</p>

<h5>{{this.ls.t('EMAIL', 'turbodepot/personal-data')}}:</h5>
<p>{{this.customerData.mail === '' ? '-' : this.customerData.mail}}</p>

<h5>{{this.ls.t('ADDRESS', 'turbodepot/personal-data')}}:</h5>
<p>{{this.customerData.direccion === '' ? '-' : this.customerData.direccion}}</p>

<h5>{{this.ls.t('COMMENTS', 'turbodepot/user-interface')}}:</h5>
<p>-</p>

<button id="confirmCustomerButton" mat-raised-button color="primary"
    (click)="closeDialog(1)">
    
    {{this.ls.t('CONFIRM_CUSTOMER', 'turbodepot/shopping')}}
    
</button>

<button mat-button color="primary"
    (click)="closeDialog(0)">
    
    {{this.ls.t('SELECT_ANOTHER_CUSTOMER', 'turbodepot/shopping')}}
    
</button>
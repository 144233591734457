import { Component, OnDestroy, AfterViewInit, ViewContainerRef, ElementRef, ViewChild } from '@angular/core';
import { ViewsService, View, LocalesService, DialogService, DialogTwoOptionComponent, ButtonContainerComponent, FadeAnimationClass } from 'turbogui-angular';
import { AppModel } from 'src/main/model/models/app.model';
import { Customer } from 'src/main/model/dtos/Customer';
import { OrderNewLineComponent } from './order-new-line.component';
import { ObjectUtils } from 'turbocommons-ts';
import { UserModel } from 'src/main/model/models/user.model';
import { OrdersNewOrderViewService } from './orders-new-order-view.service';
import { LoadOrderFileComponent } from './load-order-file.component';
import { CustomerService } from '../../../controller/customer.service';
import { CommonModule } from '@angular/common';
import { CustomerDetailsComponent } from '../../components/customer-details/customer-details.component';
import { SelectCustomerButtonComponent } from "../../components/select-customer-button/select-customer-button.component";


/**
 * Orders view
 */
@Component({
    selector: 'app-orders-new-order-view',
    standalone: true,
    imports: [CommonModule, ButtonContainerComponent, CustomerDetailsComponent, SelectCustomerButtonComponent],
    providers: [OrdersNewOrderViewService],
    templateUrl: './orders-new-order-view.component.html',
    animations: [FadeAnimationClass.getTrigger('fadeIn')],
    styleUrls: ['./orders-new-order-view.component.scss']
})


export class OrdersNewViewComponent extends View implements AfterViewInit, OnDestroy {

    
    @ViewChild(SelectCustomerButtonComponent) selectCustomerButton: SelectCustomerButtonComponent;
    

    constructor(public ls: LocalesService,
                public viewsService: ViewsService,
                public dialogService: DialogService,
                public customerService: CustomerService,
                public appModel: AppModel,
                public userModel: UserModel,
                public ordersNewOrderViewService: OrdersNewOrderViewService,
                public elementRef: ElementRef,
                public viewContainerRef: ViewContainerRef) {

        super(viewContainerRef, ordersNewOrderViewService);

        this.appModel.mainTitle = this.ls.t('NEW_ORDER', 'turbodepot/shopping');
        this.dialogService.addCloseApplicationWarning();
    }


    ngAfterViewInit() {

        // If the current user is a customer one, we will directly set its data here
        // Otherwise, we will directly call for the select customer popup
        if(this.userModel.isCustomer){

            this.ordersNewOrderViewService.order.customer.codigo = this.userModel.customer.codigo;
            this.ordersNewOrderViewService.order.customer.nombre = this.userModel.customer.nombre;
            this.ordersNewOrderViewService.order.customer.direccion = this.userModel.customer.direccion;
            this.ordersNewOrderViewService.order.customer.direccionesEnvio = this.userModel.customer.direccionesEnvio;
            this.ordersNewOrderViewService.order.customer.direccionesEnvioDetalle = this.userModel.customer.direccionesEnvioDetalle;
            this.ordersNewOrderViewService.order.customer.tieneControl = this.userModel.customer.tieneControl;
            
            this.selectCustomerButton.setCustomer(
                this.userModel.customer.codigo,
                this.userModel.customer.nombre);
            
            this.createNewOrderLine();

        }else{
            
            this.selectCustomerButton.onClick();
        }
    }
    
    
    onCustomerCleared(){
    
        this.ordersNewOrderViewService.order.customer = new Customer();
    }
    
    
    onCustomerConfirmed(event:any){
        
        this.ordersNewOrderViewService.order.customer = new Customer();
        this.ordersNewOrderViewService.order.customer.codigo = event.codigo;
        this.ordersNewOrderViewService.order.customer.nombre = event.nombre;
        
        if(this.ordersNewOrderViewService.order.orderLines.length === 0){

            this.createNewOrderLine();
        }
    }

    
    createNewOrderLine(){

        this.ordersNewOrderViewService.selectedOrderLine = -1;

        this.dialogService.addDialog(OrderNewLineComponent, {width: '900px', viewContainerRef: this.viewContainerRef});
    }


    editOrderLine(i: number){

        this.ordersNewOrderViewService.orderLineBeingEdited = ObjectUtils.clone(this.ordersNewOrderViewService.order.orderLines[i]);

        this.dialogService.addDialog(OrderNewLineComponent, {width: '900px', viewContainerRef: this.viewContainerRef});
    }


    deleteOrderLine(i: number, description:string){

        this.dialogService.addDialog(
            DialogTwoOptionComponent,
            {
                width:'500px',
                modal: false,
                texts: [this.ls.t('DELETE_ORDER_LINE', 'centroalum/app') + '?', description],
                options: [this.ls.t('DELETE', 'turbodepot/user-interface'), this.ls.t('CANCEL', 'turbodepot/user-interface')]
            },
            selection =>{

                if(selection.index === 0){

                    this.ordersNewOrderViewService.order.orderLines.splice(i, 1);
                    this.ordersNewOrderViewService.selectedOrderLine = -1;
                    this.ordersNewOrderViewService.order.total = this.ordersNewOrderViewService.calculateOrderTotal(this.ordersNewOrderViewService.order.orderLines);
                }
            });
    }
    
    
    loadLocalFile(){

        this.dialogService.addDialog(LoadOrderFileComponent, {
            modal: false,
            width: '900px',
            viewContainerRef: this.viewContainerRef
            });
    }
   
    
    /**
     * Remove subscriptions on component removal
     */
    ngOnDestroy() {

        this.appModel.mainTitle = '';
        this.dialogService.removeCloseApplicationWarning();
    }
}
